<template>
  <div class="content">
    <div class="export">
      <h2>标签 6x3.3(h)cm</h2>
      <div>第一步：选择标签excel文件，导入数据</div>
      <input type="file" accept=".xlsx,.xls" @change="change" />
    </div>
    <div class="export">
      <div><a href="templete/templete.xlsx" download="templete.xlsx">下载模板</a></div>
    </div>
    <div v-for="(t,i) in tableResult" :key="i">
      <!--每一页 18条-->
      <div class="print-list" style="page-break-after:always; ">
        <div class="item" v-for="(item,index) in t" :key="index" v-show="index < 21">
          <div class="name" :style="{'font-size': handleName(item.name)}">{{ item.name }}</div>
          <div class="size">{{ item.size }}</div>
          <div class="price" v-if="item.price">{{ handlePrice(item.price).start }}.<span>{{handlePrice(item.price).end}}</span></div>
          <div class="vip" v-if="item.vip">{{ handlePrice(item.vip).start }}.<span>{{handlePrice(item.vip).end}}</span></div>
          <div class="code-number">{{ item.code }}</div>
          <div class="code">
            <barcode :value="item.code" :options="barcode_option" 
            style="width: 152px;height:50px;letter-spacing: 1.5px;}"></barcode>
          </div>
        </div>
      </div>
    </div>

    <!-- <div>第二步：点击导出转换格式后的数据</div>
    <button @click="downloadStudent">导出</button>-->
    <div class="el-loading-mask is-fullscreen" v-show="isLoading">
      <div class="el-loading-spinner">
        <svg viewBox="25 25 50 50" class="circular">
          <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
        </svg>

        <p class="el-loading-text">Loading…</p>
      </div>
    </div> 
  </div>
</template>

<script>
import saveAs from "file-saver";
import Excel from "exceljs";
import barcode from "@xkeshi/vue-barcode";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
        barcode,
  },
  data() {
    return {
      isLoading: false,
      list: [],
      tableResult: [], // 最终数据
      // 例子
      tableData: [
        // {
        //     "sort": 1,
        //     "name": "特制冷冻鸡中翅",
        //     "size": "500g/份",
        //     "price": '40.80',
        //     "vip": '38.80',
        //     "code": 123456789
        // }
      ],
      barcode_option: {
        displayValue: false, //是否默认显示条形码数据
        background: '#fff', //条形码背景颜色
        height: '30px',
        fontSize: '12px',
        lineColor: '#000',
      },
    };
  },
  methods: {
    handlePrice(val) {
      if(!val)  {
        let a = '';
        let b = '';
        return {a,b};
      }
      let start = String(val).split('.')[0];
      let end = String(val).split('.')[1] || '00';
      end = end.length === 1 ? end + '0' : end;
      return {start,end}
    },
    getCharacterLength(str) {
      let length = 0;
      for (let i = 0; i < str.length; i++) {
        // 获取当前字符的Unicode码点
        const code = str.charCodeAt(i);
        // 判断当前字符是否为中文字符，中文字符的Unicode码点范围是0x4E00到0x9FFF
        if (code >= 0x4E00 && code <= 0x9FFF) {
          length += 2; // 中文字符占用两个长度
        } else {
          length += 1; // 英文字符占用一个长度
        }
      }
      return length;
    },
    handleName() {
      return `20px`;
      // 暂不处理
      // if(!val)  return;
      // const len = this.getCharacterLength(val);
      // let fontSize = 20;
      // if(len > 18 && len <= 24) {
      //   fontSize = 17
      // } else if(len > 24 && len <= 28) {
      //   fontSize = 15
      // } else if(len > 28 && len <= 34) {
      //   fontSize = 14
      // } else if(len > 34) {
      //   fontSize = 12
      // }
      // return `${fontSize}px`;
    },
    convertArray(array, groupSize) {
      var result = [];
      for (var i = 0; i < array.length; i += groupSize) {
        var group = array.slice(i, i + groupSize);
        result.push(group);
      }
      return result;
    },
    async change(e) {
      this.list = null;
      let file = e.target.files[0];
      if (!file) return;
      this.list = await this.read(file);
    },
    async read(file) {
      this.isLoading = true;
      let that = this;
      if (!file) return;
      return new Promise((s) => {
        let rs = new FileReader();
        rs.readAsArrayBuffer(file);
        rs.onload = async function (e) {
          let data = e.target.result;
          let wb = new Excel.Workbook();
          await wb.xlsx.load(data);
          data = wb.getWorksheet(1);
          let list = [];
          data._rows.map((item) => {
            let cells = [];
            item._cells.map((cell) => {
              let value = cell._value.model.value;
              cells.push(value);
            });
            list.push(cells);
          });

          // 处理格式
          let dataLists = list.filter((item, index) => {
            return index > 0;
          });

          console.log('dataLists', dataLists)

          // 拼接结果
          let sort = 1;
            dataLists.map((d) => {
              let name = d[1];
              let size = d[2];
              let price = d[3];
              let vip = d[4];
              let code = d[5];
              let obj = {
                sort: sort,
                name: name,
                size: size,
                price: price,
                vip: vip,
                code: code,
              };
              sort++;
              that.tableData.push(obj);
            });
           
            // alert('导入完成')
          console.log("转换结果", that.tableData);
          
           // 动态拼接打印分页
           that.$nextTick(()=>{
            setTimeout(()=>{
              that.isLoading = false;
              that.tableResult = that.convertArray(that.tableData, 21);
              console.log(that.tableResult)
            },1000)
           })
           
          s(list);
        };
      });
    },
    exportFile(header, columns, dataList, expertName) {
      const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let workbook = new Excel.Workbook();
      workbook.created = new Date();
      workbook.modified = new Date();
      let worksheet = workbook.addWorksheet("sheet1");
      worksheet.properties.defaultColWidth = 14;
      worksheet.columns = columns;
      // 设置表头
      // worksheet.getRow(1).values = [expertName];
      // worksheet.mergeCells(1, 1, 1, columns.length); //第1行  第1列  合并到第1行的第n列
      // const title = worksheet.getRow(1).getCell(1); //第一行第一个单元格
      // title.font = { size: 24 };
      worksheet.getRow(1).height = 28.6;
      worksheet.getRow(1).values = header;
      // debugger
      // 表头样式
      worksheet
        .getRow(1)
        .eachCell({ includeEmpty: true }, (cell, colNumber) => {
          worksheet.getRow(1).getCell(colNumber).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ffffff" },
            bgColor: { argb: "ffffff" },
          };
          worksheet.getRow(1).getCell(colNumber).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

      worksheet.addRows(dataList);
      // 自定义样式
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber > 1) {
          worksheet.getRow(rowNumber).height = 28.6;
        }
        worksheet
          .getRow(rowNumber)
          .eachCell({ includeEmpty: true }, (cell, colNumber) => {
            // 文字居中
            worksheet.getRow(rowNumber).getCell(colNumber).alignment = {
              vertical: "middle",
              horizontal: "center",
            };
            //边框样式
            worksheet.getRow(rowNumber).getCell(colNumber).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
      });
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: EXCEL_TYPE });
        saveAs(blob, `${expertName}.xlsx`);
      });
    },
    downloadStudent() {
      const header = ["序号", "县区名称", "分类", "使用量", "单位", "时间"];
      const columns = header.map((item) => {
        return {
          header: item,
          key: item,
        };
      });
      const dataList = this.tableData.map((item) => {
        let res = {};
        res.序号 = item.sort;
        res.县区名称 = item.area;
        res.分类 = item.catalog;
        res.使用量 = item.count;
        res.单位 = item.unit;
        res.时间 = item.time;
        return res;
      });
      this.exportFile(header, columns, dataList, "测试结果");
    },
  },
  created() {},
};
</script>


<style scoped>
.content{
  clear: both;
}

@media print {
  .export{
   display: none;
  }
  .print-list{
    page-break-after:always;
    
  }
}

.print-list{
  width: 930px;
  margin: 0 auto;
  print-color-adjust: exact;
  color-adjust: exact;
  page-break-after:always;
  height: 700px;

}
.print-list .item {
  float: left;
  width: 300px;
  margin-right: 30px;
  margin-bottom: 35px;
  height: 165px;
  background: url('../assets/cover.jpg') no-repeat;
  background-size: contain;
  position: relative;
  zoom: 0.9;
}
/* .print-list .item:nth-child(3n+1){
  margin-right: 0;
} */
.print-list .item .name{
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  left: 65px;
  top: 5px;
  line-height: 26px;
  font-family: MiSansBold, sans-serif;
}
.print-list .item .size{
  font-size: 12px;
  position: absolute;
  left: 243px;
    top: 36.5px;
    font-family: MiSansMedium, sans-serif;
}
.print-list .item .price{
  position: absolute;
  font-size: 36px;
    left: 60px;
    top: 85px;
    width: 100px;
    text-align: center;
    /* font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif */
    font-family: MiSansNormal, sans-serif;
}
.print-list .item .price span{
  font-size: 28px;
}
.print-list .item .vip{
  font-weight: 500;
  font-size: 35px;
    position: absolute;
    left: 190px;
    top: 85px;
    width: 100px;
    text-align: center;
    font-family: MiSansSemibold, sans-serif;

}
.print-list .item .vip span{
  font-size: 28px;
}
.print-list .item .code-number{
  width:150px;
  text-align: center;
  position: absolute;
  left: -32px;
  top: 72px;
  transform: rotate(-90deg);
  z-index: 9999;
  font-family: MiSansDemibold, sans-serif;
  font-size: 12px;

}
.print-list .item .code{
  position: absolute;
  left: -56px;
    top: 54px;
    transform: rotate(-90deg);
    z-index: 999;
}
/* ******************* 加载动画 **************************** */

.el-loading-mask.is-fullscreen {
  position: fixed;
}

.el-loading-mask {
  background-color: hsla(0, 0%, 100%, 0.4) !important;
}

.el-loading-mask {
  position: absolute;
  z-index: 10000;
  background-color: hsla(0, 0%, 100%, 0.9);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s;
}

.el-loading-mask.is-fullscreen .el-loading-spinner {
  margin-top: -25px;
}

.el-loading-spinner {
  top: 50%;
  margin-top: -21px;
  width: 100%;
  text-align: center;
  position: absolute;
}

.el-loading-mask.is-fullscreen .el-loading-spinner .circular {
  width: 50px;
  height: 50px;
}

.el-loading-spinner .circular {
  width: 42px;
  height: 42px;
  animation: loading-rotate 2s linear infinite;
}

svg:not(:root) {
  overflow: hidden;
}

.el-loading-spinner .el-loading-text {
  color: #ff495e !important;
}

.el-loading-spinner .el-loading-text {
  color: #20a0ff;
  margin: 3px 0;
  font-size: 14px;
}

.el-loading-spinner .path {
  stroke: #ff495e !important;
}

.el-loading-spinner .path {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke: #20a0ff;
  stroke-linecap: round;
}

@keyframes loading-rotate {
  to {
    transform: rotate(1turn);
  }
}
@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;

    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;

    stroke-dashoffset: -40px;
  }

  to {
    stroke-dasharray: 90, 150;

    stroke-dashoffset: -120px;
  }
}
</style>