<template>
  <div id="app">
    <BiaoQian3/>
    <BiaoQian5/>
    <ChengZhong></ChengZhong>
  </div>
</template>

<script>
import BiaoQian3 from './components/biaoqian-3.3.vue'
import BiaoQian5 from './components/biaoqian-5.1.vue'
import ChengZhong from './components/chengzhong.vue'
import './assets/font/index.css'
export default {
  name: 'App',
  components: {
    BiaoQian3,
    BiaoQian5,
    ChengZhong
  }
}
</script>

